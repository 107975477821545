import 'jquery-match-height';

import Swiper from 'swiper';
import 'swiper/swiper.scss';

import '../sass/style.scss';
import '../sass/article.scss';

/* ----------------------------------
  hover
---------------------------------- */
var touch = 'ontouchstart' in document.documentElement
|| navigator.maxTouchPoints > 0
|| navigator.msMaxTouchPoints > 0;

if (touch) {
  try {
    for (var si in document.styleSheets) {
      var styleSheet = document.styleSheets[si];
      if (!styleSheet.rules) continue;

      for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
        if (!styleSheet.rules[ri].selectorText) continue;

        if (styleSheet.rules[ri].selectorText.match(':hover')) {
          styleSheet.deleteRule(ri);
        }
      }
    }
  } catch (ex) {}
}

var isMobile; // タブレットかスマホか。初期処理やりサイズ時に設定

// trueでヘッダー縮小無効化
//
// サイドメニュースクロール禁止処理中はスクロールによるヘッダー縮小を無効化しないと
// ヘッダーサイズが変化してサイドメニュー表示位置がずれる
//
// modalでスクロール無効にした場合も無効化しないとヘッダーが大きくなる
var headerLock = false;

$(function(){
	setIsMobile() // タブレットorスマホ
  // loading_animation() //ローディングアニメーション
  modal_window() // モーダルウィンドウ・モバイルメニュー開閉
  //mobile_nav()　//モバイルメニュー表示切り替え
  // invew_animation() //スクロールアニメーション
  change_image() //商品詳細画像切り替え
	itemQuantity(); // 商品詳細 商品数量変更
	adjustPlusOneItemHeight(); // プラスワンアイテム高さ揃え
  smooth_scroll() //スムーススクロール
  faq_accordion() //よくある質問アコーディオン
  //login_accordion() //ログインアコーディオン
  show_moreBtn() //もっと見るボタン表示
  footer_accordion() //フッター アコーディオン
  like_button() //お気に入りボタン
  scroll_updown() // スクロール上下取得
  btn_topscroll() //最上部へ戻るボタンフェードイン
	slider() // スライダー
	itemWidget() // 商品ウィジェット
	itemList() // 商品リスト
	itemHeight() // 商品表示高さ揃え
	itemGuide() // 商品ページご利用ガイド
	newItemList() // 新商品リスト
	dashiSlider(); // 商品詳細うまだし活用術スライダー
	starBar() // レビュー評価バー
	header_search() // PCのヘッダー検索
	category_nav() // カテゴリーナビゲーション
	topHeaderVideo(); // トップページヘッダー動画
	topTabTitle(); // トップページタブコンテンツタイトル
	topTabs() // トップページタブ
	searchForm() // 検索フォーム
	sideMenu() // サイドメニュー
	headerShrink() // スクロールでヘッダー縮小
	topNavi() // トップナビ


	/* モバイル判定
	---------------------------------- */
	function setIsMobile() {
		var w = $(window).width();
		if (w < 768) {
			isMobile = true;
		} else {
			isMobile = false;
		}
	}

  /* ローディングアニメーション
  ---------------------------------- */
  function loading_animation() {
    $('#page-contents').css('display','none');
    $(window).on('load',function(){
      $('#loading-animation').delay(400).fadeOut(400);
      $('#page-contents').delay(800).css('display', 'block');
    });
    setTimeout(function(){
    	$('#loading-animation').delay(400).fadeOut(400);
    	$('#page-contents').delay(800).css('display', 'block');
    }, 6000 );
  }

  /* モーダルウィンドウ・モバイルメニュー開閉
  ---------------------------------- */
  function modal_window() {
		var posi = null;

    $('.modal-trigger').on('click', function(e){
        e.preventDefault();

				$('.modal-close').trigger('click');

        posi = $(window).scrollTop();
        var target = $(this).attr('href');

        if ($(target).is(':hidden')) {
          // $('body').addClass('-modalOpen').css({position:'fixed',top: -1 * posi});
					$('body').addClass('-modalOpen');
          $(target).fadeIn();
          if($(this).hasClass('btn-mobile')) {
            $(this).addClass('-active');
          }
        } else {
					// $('body').removeClass('-modalOpen').css('position', 'static');
					$('body').removeClass('-modalOpen');
          // $('html, body').prop({scrollTop: posi});
          $(target).fadeOut();
          $('.btn-mobile').removeClass('-active');
        }
    });

    $('.modal-close').on('click', function(e){
			$('.modal-window').fadeOut();
      $('body').removeClass('-modalOpen').css('position', 'static');
      if (posi !== null) $('html, body').prop({scrollTop: posi});
      $('.btn-mobile').removeClass('-active');
    });
  }

  /*  モバイルメニュー　表示切り替え
  ---------------------------------------------------*/
  function mobile_nav() {
    var mobile_nav = $('#mobile-navigation');
    var mobile_open = $('#mobile-open');
		var mobile_menu = $('#mobile-menu');

		var isModalActive = function() {
			return mobile_open.hasClass('-active') || mobile_menu.hasClass('-active');
		}

    if(isModalActive()) {
    } else {
      mobile_nav.hide();
    }
    $(window).scroll(function () {
      if ($(this).scrollTop() > 350) {
        mobile_nav.stop(true,true).fadeIn();
      } else {
        if(isModalActive()) {
        } else {
          mobile_nav.stop(true,true).fadeOut();
        }
      }
    });
    $('body').on('touchmove', function(){
      if ($(this).scrollTop() > 350) {
        mobile_nav.stop(true,true).fadeIn();
      } else {
        if(isModalActive()) {
        } else {
          mobile_nav.stop(true,true).fadeOut();
        }
      }
    });
  }

	// 商品詳細　商品数量変更
	function itemQuantity() {
		if (!$('.item-purchase').length) return;

		function update(elm, delta) {
			var $input = $('.item-purchase__quantity', elm.parentNode);
			if (!$input.length) return;

			var value = parseInt($input.val());
			if (isNaN(value)) {
				$input.val(1);
			} else {
				$input.val(Math.max(1, value + delta));
			}
		}

		$('.item-purchase__minus').on('click', function (e) {
			update(e.target, -1);
		})

		$('.item-purchase__plus').on('click', function (e) {
			update(e.target, 1);
		})
	}

	// プラスワンアイテム高さ揃え
	function adjustPlusOneItemHeight() {
		if (!$('.optional-items__item').length) return;

		function adjustHeight(query) {
			const $elm = $(query);

			$elm.css('height', 'auto');

			let maxH = 0;
			$elm.each((i, elm) => {
				maxH = Math.max(maxH, $(elm).outerHeight());
			});

			$elm.css('height', maxH + 'px');
		}

		$(window).on('load resize', event => {
			adjustHeight('.optional-items__item-title');
			adjustHeight('.optional-items__item-desc');
		});
	}

  /* スクロールアニメーション
  ---------------------------------- */
  function invew_animation() {
    $('.-fadeup').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
      if (isInView) {
        $(this).stop().addClass('-show');
      }
    });
  }

  /* 商品詳細画像切り替え
  ---------------------------------- */
  function change_image() {
    var $mainImg = $(".item-mainImage");
		if (!$mainImg.length) return;
		const $main = $('.list-itemvisual');
    var $thumbnail = $(".item-thumbnailImage");
		var $prev = $(".item-preview__control-prev");
		var $next = $(".item-preview__control-next");
		var $index = $(".item-preview__control-index");
		var $total = $(".item-preview__control-total");
    var fadeTime = 400;
    var showTime = 800;

    $mainImg.hide().eq(0).show();
    $thumbnail.eq(0).addClass('-active');
		$index.text(1);
		$total.text($thumbnail.length);

		function update(index) {
			$thumbnail.stop().removeClass('-active');
      $thumbnail.eq(index).stop().addClass('-active');

      $mainImg.stop().fadeOut(fadeTime);
      $mainImg.eq(index).stop().fadeIn(showTime);
			$index.text(index + 1);
		}

		function prev() {
			let index = $(".item-thumbnailImage.-active").index();
			update((index - 1 + $thumbnail.length) % $thumbnail.length);
		}

		function next() {
			let index = $(".item-thumbnailImage.-active").index();
			update((index + 1) % $thumbnail.length);
		}

		var pageX = null;
		const mainW = $main.width();
		$main.on('touchstart', function(e) {
			//e.preventDefault();
			const touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
			pageX = touch.pageX;
		});

		$main.on('touchend', function(e) {
			e.preventDefault();
			const touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
			if (pageX !== null) {
				const x = (touch.pageX - pageX) * 100 / mainW;
				if (Math.abs(x) >= 15) {
					if (x > 0) {
						prev();
					} else {
						next();
					}
				}
			}
		});

    $thumbnail.on('click', function () {
      let index = $(this).index();
      update(index);
    });

		$prev.on('click', function (e) {
			prev();
		});

		$next.on('click', function (e) {
			next();
		});
  }

  /*  スムーススクロール
  ---------------------------------------------------*/
	function smooth_scroll() {
		$(window).on('load', function () {
			$('a[href^="#"]').click(function () {
				var speed = 500;
				var href = $(this).attr("href");
				var target = $(href == "#" || href == "" ? 'html' : href);

				var h = 0;
				if (!isMobile) {
					var $header = $('.header-page');
					if ($header.length) h += $header.outerHeight();
				}

				var $nav = $('.nav-wrapper');
				if ($nav.length) h += $nav.outerHeight();

				var position = target.offset().top - h;
				if(href == "#"){
					position = target.offset().top
				}
				$("html, body").stop().animate({
					scrollTop: position
				}, speed, "swing");
				return false;
			});
		});
	}

  /*  FAQ　アコーディオン
  ---------------------------------------------------*/
  function faq_accordion() {
		const $accordion = $('.p-faq-detail__item ._question');
		if (!$accordion.length) return;

  	$accordion.on('click', function() {
      if ($(this).hasClass('_open')) {
        $(this).stop().removeClass('_open');
        $(this).next('._answer').stop().slideUp();
      } else {
        $(this).stop().addClass('_open');
        $(this).next('._answer').stop().slideDown();
      }
    });
  }

  /*  ログイン　アコーディオン
  ---------------------------------------------------*/
  function login_accordion() {
		const $accordion = $('.p-member-login__block-title--accordion');
		if (!$accordion.length) return;

		$accordion.on('click', function() {
			if (isMobile) {
				if ($(this).hasClass('p-member-login__block-title--on')) {
					$accordion.stop().removeClass('p-member-login__block-title--on');
					$accordion.next().stop().slideUp();
				} else {
					$accordion.not($(this)).stop().removeClass('p-member-login__block-title--on');
					$(this).stop().addClass('p-member-login__block-title--on');
					$(this).next().stop().slideToggle();
					$accordion.not($(this)).next().stop().slideUp();
				}
			}
		});

    $(window).on('load resize', function () {
      setIsMobile(); // モバイル判定更新
			if (!isMobile) {
				$accordion.next().stop().show();
			}
    });
  }

  /*  もっと見るボタンの表示・機能
  ---------------------------------------------------*/
  function show_moreBtn() {
		$(window).on('load resize', function () {
      setIsMobile(); // モバイル判定更新
			if (!isMobile) {
				if($('.top-selling').has('.column-two:eq(4)')) {
					$('.column-two:eq(4)').parents('.top-selling').addClass('-more');
				}
			} else {
				$('.top-selling').removeClass('-more');
			}
    });
    $('.top-selling .btn-more').click(function(){
			$(this).siblings('.flex-two').find('.column-two').slice(4).fadeIn();
			$(this).hide();
		});
  }

  /*  フッター 　アコーディオン
  ---------------------------------------------------*/
  function footer_accordion() {
    $('.footer-plus').click(function(){
      if ($(this).hasClass('-active')) {
        $('.footer-plus').stop().removeClass('-active');
        $('.footer-plus').next('.footer-navchild').stop().slideUp();
      } else {
        $('.footer-plus').not($(this)).stop().removeClass('-active');
        $(this).stop().addClass('-active');
        $(this).next('.footer-navchild').stop().slideToggle();
        $('.footer-plus').not($(this)).next('.footer-navchild').stop().slideUp();
      }
    });
  }

  /*  お気に入りボタン
  ---------------------------------------------------*/
  function like_button() {
    $('.btn-like').click(function(){
      $(this).stop().toggleClass('-active');
    });
  }

  /*  スクロール上下取得
  ---------------------------------------------------*/
  function scroll_updown() {
    var before = $(window).scrollTop();
    $(window).scroll(function() {
    	var after = $(window).scrollTop();
    	if(before > after) {
    		$('.nav-category').removeClass('-active');
    	} else if(before < after) {
    		$('.nav-category').addClass('-active');
    	}
    	before = after;
    });
  }

  /*  最上部へ戻るボタン　フェードイン
  ---------------------------------------------------*/
  function btn_topscroll() {
    var top_scroll = $('#btn-topscroll');
		if (!top_scroll.length) return;

		var buttonBottom = 32;
		var $itemWidget = $('.item-widget');
		if ($itemWidget.length) {
			buttonBottom = $itemWidget.outerHeight();
		}

		var onScroll = function () {
			if (isMobile) {
				top_scroll.hide();
				return;
			}

			if ($(this).scrollTop() > 350) {
        top_scroll.fadeIn();
      } else {
        top_scroll.fadeOut();
      }

			var bottom = $(window).scrollTop() + $(window).height();
			var footer = $('#footer-site').offset().top;
			if (bottom > footer + top_scroll.outerHeight() + buttonBottom) {
				$('#btn-topscroll').css({position: 'absolute', bottom: ($('#footer-site').outerHeight() - 60) + 'px'});
			} else {
				$('#btn-topscroll').css({position: 'fixed', bottom: buttonBottom + 'px'});
			}
		};
		if (top_scroll.css('display') != 'none') {
    	top_scroll.hide();
    	$(window).scroll(onScroll);
    	$('body').on('touchmove', onScroll);
		}
  }

	/*  スライダー
	---------------------------------------------------*/
	/**
	 * スライダーの初期化
	 */
	function slider() {
		if ($('.js-top-slider').length) {
			var slider = $('.js-top-slider');
			slider
				.on('init', function(e, slick) {
					// サムネイルのカレント設定
					setSliderThumbCurrent(slick.slickCurrentSlide());

					// プログレスバーのリセット
					resetProgress();
				})
				.on('beforeChange', function(e, slick, currentSlide, nextSlide){
					// サムネイルのカレント設定
					setSliderThumbCurrent(nextSlide);

					// プログレスバーのリセット
					resetProgress();
				});
			$('.js-top-slider-prev').on('click', function() {
				$('.js-top-slider').slick('slickPrev');
			});
			$('.js-top-slider-next').on('click', function() {
				$('.js-top-slider').slick('slickNext');
			});
			var thumb = $('.js-top-slider-thumb');
			thumb.children().on('click', function() {
				changeSlide(thumb.children().index(this));
			});
			slider.slick({
				mobileFirst: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000,
				speed: 500,
				responsive: [{
					breakpoint: 768,
					settings: {
						centerMode: true,
						variableWidth: true,
					}
				}]
			});
		}
	};

	/**
	 * サムネイルのカレント設定
	 * @param _i
	 */
	function setSliderThumbCurrent(_i) {
		$('.js-top-slider-thumb').children().removeClass('-is-active');
		$('.js-top-slider-thumb').children().eq(_i).addClass('-is-active');
	}

	/**
	 * プログレスバーのリセット
	 */
	function resetProgress() {
		$('.js-top-slider-progress').css({width: 0});
		$('.js-top-slider-progress').stop().animate(
			{width: '100%',},
			3000,
			'linear');
	}

	/**
	 * スライダーの切り替え
	 * @param _i
	 */
	function changeSlide(_i) {
		// スライダー切り替え
		$('.js-top-slider').slick('slickGoTo', _i);

		// サムネイルの切り替えやプログレスバーのリセットは、slickのbeforeChangeイベントで呼び出すため、改めてここに定義する必要はない
	}

	// 商品ウィジェット
	function itemWidget() {
		const $widget = $('.item-widget');
		if (!$widget.length) return;

		function onScroll(e) {
			var top = $(window).scrollTop();
			var bottom = top + $(window).height();
			var footer = $('#footer-site').offset().top;
			var shouldHide = false;

			if (bottom > footer) {
				// フッターに入ったら非表示判定
				shouldHide = true;
			} else if (!isMobile) {
				// 商品フォームに入ったら非表示判定
				var formTop = $('.item-form').offset().top
				if (top < formTop && bottom > formTop) {
					shouldHide = true;
				} else {
					var formBot = formTop + $('.item-form').height();
					if (top < formBot && bottom > formBot ||
						top > formTop && bottom < formBot) {
							shouldHide = true;
					}
				}
			}

			const className = 'item-widget--hidden';
			if (shouldHide && !$widget.hasClass(className)) {
				// 非表示
				$widget.addClass(className);
				setTimeout(function() {
					$widget.hide();
				}, 500);
			} else if (!shouldHide && $widget.hasClass(className)) {
				// 表示
				$widget.css('display', 'flex');
				setTimeout(function() {
					$widget.removeClass(className);
				}, 100);
			}
		}

		$(window).scroll(onScroll);
    $('body').on('touchmove', onScroll);
	}

	// 商品リスト
	function itemList() {
		function getSlidesPerGroup() {
			return isMobile? 2: 4;
		}

		function updatePageCount(swiper) {
			const pageCount = Math.ceil(
			swiper.slides.length / getSlidesPerGroup());
			$('.l-item-list__control-page-count', swiper.el.parentNode)
				.text(pageCount);
			return pageCount;
		}

		function updatePageIndex(swiper) {
			const pageIndex = Math.ceil(
				swiper.activeIndex / getSlidesPerGroup()) + 1;

			$('.l-item-list__control-page-index', swiper.el.parentNode)
				.text(pageIndex);
			return pageIndex;
		}

		function slideTo(swiper, delta) {
			const group = getSlidesPerGroup();
			let index = swiper.activeIndex;
			const length = swiper.slides.length;

			index += delta * group;
			index = Math.max(0, Math.min(index, length - group));
			swiper.slideTo(index);
		}

		function init(elm) {
			if ($(elm).data('swiper')) return;

			const swiper = new Swiper(elm, {
				slidesPerView: 5,
				// slidesPerGroup: 1,
				longSwipesRatio: 0.16,
				observer: true,
				observeParents: true,
				centeredSlides: true,
				breakpoints: {
					768: {
						slidesPerView: 9,
						// slidesPerGroup: 3,
					}
				},
			});

			updatePageCount(swiper);

			swiper.on('slidesLengthChange', function(swiper) {
				updatePageCount(swiper);
				jQuery.fn.matchHeight._update();
			});

			$(window).on('resize', e => {
				let c = updatePageCount(swiper);
				let i = updatePageIndex(swiper);
				if (i >= c) {
					slideTo(swiper, c - i);
				}
				jQuery.fn.matchHeight._update();
			});

			swiper.on('slideChange', function(swiper) {
				updatePageIndex(swiper);
			});

			$(elm).data('swiper', swiper);

			$('.l-item-list__control-prev', $(elm).parent()).on('click', function(e) {
				slideTo(swiper, -1);
			});

			$('.l-item-list__control-next', $(elm).parent()).on('click', function(e) {
				slideTo(swiper, 1);
			});

			$('.l-item-list__item-tags', elm).matchHeight();
			$('.l-item-list__item-name', elm).matchHeight();
			$('.l-item-list__item-price', elm).matchHeight();
			$('.l-item-list__item-desc', elm).matchHeight();
		}

		const $list = $('.l-item-list__container');
		$list.each(function (i, elm) {
			init(elm);
		});

		// カート追加モーダル監視
		function observeModal(node) {
			const observer = new MutationObserver(mutations => {
				mutations.forEach(mutation => {
						mutation.addedNodes.forEach(node => {
							// console.log(node);
							if (node.classList && node.classList.contains('l-item-list')) {
								$('.l-item-list__container', node).each((i, elm) => {
									init(elm);
								});
							}
						});
				});
			});
			observer.observe(target, {childList: true, subtree: true});
		}

		const target = document.getElementById('page-contents');
		if (target) {
			// console.log(target);
			const observer = new MutationObserver(mutations => {
					mutations.forEach(mutation => {
							mutation.addedNodes.forEach(node => {
								// console.log(node);
								if (node.id == 'modal_cart_in') {
									observeModal(node);
								}
							});
					});
			});
			observer.observe(target, {childList: true});
		}
	}

	// 商品表示高さ揃え
	function itemHeight() {
		//トップ等にあるスライドする商品リスト
		const $catItemList = $('.category-item-list__items');
		if ($catItemList.length) {
			$('.category-item-list__item-tags').matchHeight();
			$('.category-item-list__item-name').matchHeight();
			$('.category-item-list__item-price').matchHeight();
			$('.category-item-list__item-desc').matchHeight();
		}

		const $cItemList = $('.c-item-list__items');
		if ($cItemList.length) {
			$('.c-item-list__item-tags').matchHeight();
			$('.c-item-list__item-name').matchHeight();
			$('.c-item-list__item-price').matchHeight();
			$('.c-item-list__item-desc').matchHeight();
		}

		const $bagList = $('.p-cart-shop-bag__bags');
		if ($bagList.length) {
			$('.p-cart-shop-bag__bag-name').matchHeight();
		}

		const $favList = $('.p-member-favorite__items');
		if ($favList.length) {
			$('.p-member-favorite__item-tags').matchHeight();
			$('.p-member-favorite__item-name').matchHeight();
			$('.p-member-favorite__price-block').matchHeight();
		}
	}

	// 商品ページご利用ガイド
	function itemGuide() {
		const $guide = $('.c_guide');
		if (!$guide.length) return;

		// ご利用ガイド移動
		const $pcSib = $('.list-thumbnails');
		const $moSib = $('.item-material');
		function moveGuide() {
			if (isMobile) {
				if ($guide.prev().get(0) == $pcSib.get(0)) {
					$guide.insertAfter($moSib);
				}
			} else {
				if ($guide.prev().get(0) == $moSib.get(0)) {
					$guide.insertAfter($pcSib);
				}
			}
		}
		$(window).on('resize', e => {
			moveGuide();
		});
		moveGuide();

		// 商品詳細ページのご利用ガイドのモバイル時の開閉
		const $list = $('.c_guide_inner ul');
		const $more = $('.more');
		const CLOSED_H = 175;
		var height = null;

		function updateHeight() {
			if (isMobile) {
				if (!height) {
					height = $list.height();
					$list.css({height: CLOSED_H + 'px'}, 200);
					$more.removeClass('show');
				}
			} else {
				if (height) {
					$list.css({height: ''});
					height = null;
					$more.addClass('show');
				}
			}
		}

		$more.on('click', function() {
			const $this = $(this);
			if ($this.hasClass('show')) {
				$(this).removeClass('show');
				$list.animate({height: CLOSED_H + 'px'}, 200);
			} else {
				$(this).addClass('show');
				if (height) {
					$list.animate({height: height + 'px'}, 200);
				}
			}
		});

		$(window).on('resize', e => {
			updateHeight();
		});
		updateHeight();
	}

	// 新商品リスト
	function newItemList() {
		const $list = $('.new-item-list__container');
		if (!$list.length) return;

		function getSlidesPerGroup() {
			return isMobile? 1: 4;
		}

		function updatePageCount(swiper) {
			const pageCount = Math.ceil(
			swiper.slides.length / getSlidesPerGroup());
			$('.new-item-list__control-page-count', swiper.el.parentNode)
				.text(pageCount);
			return pageCount;
		}

		function updatePageIndex(swiper) {
			const pageIndex = Math.ceil(
				swiper.activeIndex / getSlidesPerGroup()) + 1;

			$('.new-item-list__control-page-index', swiper.el.parentNode)
				.text(pageIndex);
			return pageIndex;
		}

		function slideTo(swiper, delta) {
			const group = getSlidesPerGroup();
			let index = swiper.activeIndex;
			const length = swiper.slides.length;

			index += delta * group;
			index = Math.max(0, Math.min(index, length - group));
			swiper.slideTo(index);
		}

		$list.each(function (i, elm) {
			const swiper = new Swiper(elm, {
				slidesPerView: 4,
				// slidesPerGroup: 1,
				longSwipesRatio: 0.16,
				observer: true,
				observeParents: true,
				centeredSlides: true,
				breakpoints: {
					768: {
						slidesPerView: 4,
						centeredSlides: false,
						// slidesPerGroup: 3,
					}
				},
			});

			updatePageCount(swiper);

			swiper.on('slidesLengthChange', function(swiper) {
				updatePageCount(swiper);
				jQuery.fn.matchHeight._update();
			});

			$(window).on('resize', e => {
				let c = updatePageCount(swiper);
				let i = updatePageIndex(swiper);
				if (i >= c) {
					slideTo(swiper, c - i);
				}
				jQuery.fn.matchHeight._update();
			});

			swiper.on('slideChange', function(swiper) {
				updatePageIndex(swiper);
			});

			$(elm).data('swiper', swiper);
		});

		$('.new-item-list__control-prev').on('click', function(e) {
			const $list = jQuery('.new-item-list__container', e.target.parentNode.parentNode);
			slideTo($list.data('swiper'), -1);
		});

		$('.new-item-list__control-next').on('click', function(e) {
			const $list = jQuery('.new-item-list__container', e.target.parentNode.parentNode);
			slideTo($list.data('swiper'), 1);
		});
	}

	// 商品詳細うまだし活用術スライダー
	function dashiSlider() {
		const $list = $('#dashi_box_06 .swiper-container');
		if (!$list.length) return;

		$list.each(function (i, elm) {
			const swiper = new Swiper(elm, {
				slidesPerView: 1,
        loop: true,
			});

			swiper.on('slideChange', function(swiper) {
				const index = swiper.realIndex;
				const $tabs = $('#dashi_box_06 .button li');
				$tabs.removeClass('on');
				$tabs.slice(index, index + 1).addClass('on');
			});

			$(elm).data('swiper', swiper);
		});

		$('.arrow--left').on('click', function(e) {
			const $list = jQuery('.swiper-container', e.target.parentNode);
			$list.data('swiper').slidePrev();
		});

		$('.arrow--right').on('click', function(e) {
			const $list = jQuery('.swiper-container', e.target.parentNode);
			$list.data('swiper').slideNext();
		});

		$('#dashi_box_06 .button li').on('click', function(e) {
			const $list = jQuery('.swiper-container', e.target.parentNode.parentNode.parentNode);
			$list.data('swiper').slideTo($(e.target).index() + 1);
		});
	}

	// レビューの評価バー
	function starBar() {
		const $bars = $('.p-item-review__star-row-bar');
		if ($bars.length) {
			$bars.each(function() {
				const $this = $(this);
				const m = $this.next().text().match(/^(\s*\d+)%/);
				if (!m) return;
				var p = Math.min(parseInt(m[1]), 100);
				$(this).append($('<div>', {style: "width: " + p + "%;"}));
			});
		}
	}

	/*  ヘッダーの検索
	---------------------------------------------------*/
	function header_search() {
		$('.js-header-search-btn').on('click', function() {
			if (!isMobile) {
				if ($('.js-header-search').is(':visible')) {
					$('.js-header-search').stop().slideUp();
				} else {
					$('.js-header-search').stop().slideDown();
				}
			}
		});

		$(window).on('load resize', function () {
			setIsMobile(); // モバイル判定更新
			if (isMobile) {
				$('.js-header-search').hide();
			}
		});
	}

	/*  カテゴリーナビゲーション
	---------------------------------------------------*/
	function category_nav() {

		// 監視対象、交差対象の取得
		var observee = $('.position-now');
		var nav = $('.nav-category');

		if (nav.length && observee.length) {

			// 監視対象にindexを設定
			var cateIndex = 0;
			observee.each(function() {
				$(this).attr('data-cate-index', cateIndex);
				cateIndex++;
			})

			var $header = $('.header-page');
			var h = $header.length? $header.height(): 0;

			// スクロール監視
			// var diff = $(window).height() - nav.height() - h;
			var diff = nav.height() + isMobile? 0: h;
			var observer = setCategoryObserver(diff);

			// リサイズ時に監視設定をリセット
			$(window).on('resize', function() {
				observer = resetObserver(observer);
			});
		}
	}

	/**
	 * スクロールを監視して、基準を満たした際にカレント処理を実行
	 * @param _mb 交差対象と画面の余白高さ
	 * @returns {IntersectionObserver}
	 */
	function setCategoryObserver(_mb) {
		// 監視対象の取得
		var observee = $('.position-now');

		// 監視イベントの定義
		var options = {
			root: null,
			// rootMargin: '0% 0% ' + (- _mb) + 'px 0%',
			// rootMargin: -_mb + 'px 0% 0% 0%',
			rootMargin: "0px",
			threshold: 0.4
		};
		var observer = new IntersectionObserver(function(entries) {
			// 交差時の処理
			entries.forEach(function(entry) {
				// これから領域内に入ろうとしている場合
				if(entry.isIntersecting) {
					// インデックスを取得し切り替え
					var index = $(entry.target).attr('data-cate-index');
					// console.log(index);
					changeCategory(index);
				}
			})
		}, options);

		// 監視
		observee.each(function() {
			var elm = $(this)[0]; // jqueryオブジェクト⇒DOMエレメントの変換
			observer.observe(elm);
		})
		return observer;
	}

	/**
	 * カレント制御
	 * @param secNum
	 */
	function changeCategory(secNum) {
		var nav = $('.nav-category');

		// リセット
		nav.find('.btn-navigation').removeClass('-active');

		// 対象要素にクラス付与
		var currentLi = nav.find('.list').eq(secNum);
		currentLi.find('.btn-navigation').addClass('-active');

		// スクロール
		var speed = 500;
		var ofsFrom = currentLi.offset().left; // ディスプレイに対する表示位置
		var ofsTo = ofsFrom + currentLi.width(); // ディスプレイに対する表示位置
		var dispFrom = nav.offset().left; // ディスプレイに対するラッパーの表示位置
		var dispTo = dispFrom + nav.width(); // ディスプレイに対するラッパーの表示位置
		var currentScrollLeft = nav.scrollLeft();

		if (ofsFrom < dispFrom) {
			nav.stop().animate({
				scrollLeft: currentScrollLeft + (ofsFrom - dispFrom),
			}, speed, "swing");
		}
		else if (ofsTo > dispTo) {
			nav.stop().animate({
				scrollLeft: currentScrollLeft + (ofsTo - dispTo),
			}, speed, "swing");
		}
	};

	/**
	 * 監視処理のリセット
	 * @param _observer
	 * @returns {IntersectionObserver}
	 */
	function resetObserver(_observer) {
		var nav = $('.nav-category');

		// 現在の監視処理を停止
		_observer.disconnect();

		var $header = $('.header-page');
		var h = $header.length? $header.height(): 0;

		// 監視再設定
		// var diff = $(window).height() - nav.height();
		var diff = nav.height() + h;

		return setCategoryObserver(diff);
	}

	// トップページヘッダー動画
	function topHeaderVideo() {
		const $videos = $('.top-items__tab-content video.video_content');

		if ($videos.length) {
			$videos.on('inview', function() {
				this.play();
			});
		}
	}

	// トップページタブコンテンツタイトル
	function topTabTitle() {
		const $vcs = $('.top-items__tab-content .video_contents');
		if (!$vcs.length) return;

		// SP用要素追加
		const types = ['mentai', 'dashi', 'nabe'];
		$vcs.each(function() {
			const $this = $(this);
			var type = '';
			for (var i = 0; i < types.length; ++i) {
				if ($this.hasClass(types[i])) {
					type = types[i];
				}
			}

			$this.after($('<div>')
				.addClass('top-items__tab-title')
				.addClass('top-items__tab-title--' + type));
		});

		// PC/SPでタブタイトルの要素を移動
		function update() {
			if (isMobile) {
				$vcs.each(function() {
					const $this = $(this);
					const $txt = $('.txt', this);
					if (!$txt.length) return;
					$this.next().append($txt);
				});
			} else {
				$vcs.each(function() {
					const $this = $(this);
					var $txt = $('.txt', this);
					if ($txt.length) return;
					$txt = $('.txt', $this.next());
					$this.append($txt);
				});
			}
		}

		$(window).on('resize', e => {
			update();
		});
		update();
	}

	// トップページタブ
	function topTabs() {
		const $anchors = $('.top-items__tab-anchor');

		if ($anchors.length) {
			function putAnchors() {
				const h = $('.top-items__tabs').outerHeight();

				$anchors.each((i, elm) => {
					$(elm).css('top', -h + 'px');
				});
			}
			putAnchors();
			$(window).on('load resize', e => {
				putAnchors();
			});
		}
	}

	function searchForm() {
		// 検索
		if ($('.header-search-form').length) {
			// 検索ダイアログキャンセル
			var form = $('.header-search-form').get(0);
			$('html').on('click', function(e) {
				var cancel = true;
				var elm = e.target;
				while (elm) {
					if (elm == form) {
						cancel = false;
						break;
					}
					elm = elm.parentNode;
				}
				if (cancel) {
					$('.header-search-form-content').fadeOut();
				}
			});
			$('.header-search-form-text').on('focus', function(e) {
				$('.header-search-form-content').fadeIn().css('display', 'flex');
			});
		}
	}

	function sideMenu() {
		if ($('.header-nav').length) {
			var scrollTop = null;

			// PCメニュー
			$('.header-nav .item.-menu').on('click', function(e) {
				e.preventDefault();
				var $menu = $('.header-side-menu');

				if ($menu.hasClass('hidden')) {
					var $header = $('.header-page');
					var h = ($header.offset().top - $(window).scrollTop()) + $header.outerHeight()

					headerLock = true;

					// スクロール禁止
					scrollTop = $(window).scrollTop();
					$('body').addClass('hieader-side-menu-no-scroll').css({
						top: -scrollTop + 'px',
					});

					// サイドメニュー表示
					$('.header-side-menu-modal-bg')
						.css('top', h + 'px').fadeIn();

					$menu
						.show()
						.css({
							top: $header.outerHeight() + 'px',
							height: 'calc(100vh - ' + h + 'px)'
						});
					setTimeout(function() {
						$menu.removeClass('hidden');
					});
				} else {
					headerLock = false;

					// スクロール禁止解除
					$('body').removeClass('hieader-side-menu-no-scroll').css({
						top: 'auto',
					});

					if (scrollTop !== null) {
						$('html, body').scrollTop(scrollTop);
					}

					// サイドメニュー非表示
					$('.header-side-menu-modal-bg').fadeOut();

					$menu.addClass('hidden');
					setTimeout(function() {
						$menu.hide();
					}, 500);
				}
			});

			// PCメニュー サイドメニュー
			$('.header-side-menu-group').on('click', function(e) {
				e.preventDefault();

				var openClassName = 'header-side-menu-group--open';
				var $this = $(this);
				if ($this.hasClass(openClassName)) {
					$this.next().slideUp();
					$this.removeClass(openClassName);
				} else {
					$this.next().slideDown();
					$this.addClass(openClassName);
				}
			});

			// サイドメニューサブメニュー表示
			var event = null;
			$('.header-side-menu-right').on('mouseover', e => {
				event = e;

				setTimeout(function() {
					const elm = document.querySelectorAll( ":hover" );
					if (!event || event.target != elm[elm.length-1]) return;

					const category = $(event.target).data('category');

					let shouldHide = true;
					let t = event.target;
					while (t) {
						if ($(t).hasClass('header-side-menu-left')) {
							shouldHide = false;
							break;
						}
						t = t.parentNode;
					}

					if (shouldHide) {
						$('.header-side-menu-left:visible').hide();
					}

					if (event.target.tagName == 'A' && category) {
						$(event.target).next().show();
					}
				}, 100);
			});

			// サイドメニュー非表示
			$('.header-side-menu').on('mouseleave', e => {
				$('.header-side-menu-left:visible').hide();
			});

			$('.header-side-menu-close-button').on('click', e => {
				$('.header-nav .item.-menu').trigger('click');
			});

			$(window).on('resize', e => {
				var $menu = $('.header-side-menu');

				if (!$menu.hasClass('hidden')) {
					$menu.addClass('hidden');
					setTimeout(function() {
						$menu.hide();
					}, 500);
				}
			})
		}
	}

	// スクロールでヘッダー縮小
	function headerShrink() {
		if (!$('.header-page').length) return;

		var isShrinking = false;
		var timeoutId = null;
		const shrinkThreshold = 40;
		$(window).on('load scroll', function(){
			// サイドメニュースクロール禁止処理中はスクロールによるヘッダー縮小を無効化する
			if (headerLock) return;

			var documentTop = $(this).scrollTop();
			var $header = $('.header-page');

			if (documentTop > shrinkThreshold && !$header.hasClass('fix') && !isShrinking) {
				isShrinking = true;
				$header.addClass('fix');

				if (timeoutId === null) {
					timeoutId = setTimeout(() => {
						isShrinking = false;
						timeoutId = null;
					}, 500);
				}
			} else if (documentTop <= shrinkThreshold && $header.hasClass('fix') && !isShrinking) {
				$header.removeClass('fix');
			}
		});
	}

	// トップナビ
	function topNavi() {
		const $navi = $('.top-items__tabs');
		if (!$navi.length) return;

		function isSp(h) {
			return h < 65;
		}

		$(window).on('load scroll resize', function() {
			const h = $('.header-page').height();
			const top = $(window).scrollTop();
			const $row1 = $('.top-items__tab-content--mentaiko');
			const row1 = $row1.offset().top;
			const $row2 = $('.top-items__tab-content--dashi');
			const row2 = $row2.offset().top;
			const $row3 = $('.top-items__tab-content--nabe');
			const row3 = $row3.offset().top;
			const $row4 = $('.top-kyushu');
			const row4 = $row4.offset().top;

			if (top + (isSp(h)? 0: h) >= row1 - $navi.height() - 1
				&& top + (isSp(h)? 0: h) < row4 - $navi.height()) {
				$navi.addClass('top-items__tabs--visible');
			} else {
				$navi.removeClass('top-items__tabs--visible');
			}


			if (top + (isSp(h)? 0: h) < row2 - $navi.height() - 1) {
				$navi.addClass('top-items__tabs--mentaiko');
				$navi.removeClass('top-items__tabs--dashi');
				$navi.removeClass('top-items__tabs--nabe');
			} else if (top + (isSp(h)? 0: h) < row3 - $navi.height() - 1) {
				$navi.removeClass('top-items__tabs--mentaiko');
				$navi.addClass('top-items__tabs--dashi');
				$navi.removeClass('top-items__tabs--nabe');
			} else {
				$navi.removeClass('top-items__tabs--mentaiko');
				$navi.removeClass('top-items__tabs--dashi');
				$navi.addClass('top-items__tabs--nabe');
			}
		});
	}
});

	/*  21.09.12 追加｜商品詳細ページ > 果樹蔵
	---------------------------------------------------*/
	$(function(){
		if (!$('#kj_osusume').length) return;

		$(window).on('load resize', function(){
			var WindowWidth = $(window).width();
			if(WindowWidth < 768){
				$("#kj_osusume .col > .img").each(function(){
					$(this).prev().children('h2').after($(this));
					});
			} else {
				$("#kj_osusume .col .inner > .img").each(function(){
					$(this).parent().after($(this));
					});
			}
		});
	});

	/*  21.09.12 追加｜商品詳細ページ > 各コーナーにおける高さ揃え
	---------------------------------------------------*/
$(function(){
	if ($('.c_himitsu').length) {
		$('.c_himitsu .box .img').matchHeight();
		$('.c_himitsu .box .txt .ttl').matchHeight();
		$('.c_himitsu .box .txt .exp').matchHeight();
	}

	if ($('.c_oishisa-set').length) {
		$('.c_oishisa-set .box .img').matchHeight();
		$('.c_oishisa-set .box .txt .ttl').matchHeight();
		$('.c_oishisa-set .box .txt .exp').matchHeight();
	}

	if ($('.c_plus-one-item').length) {
		$('.c_plus-one-item .box .catch').matchHeight();
		$('.c_plus-one-item .box .name').matchHeight();
	}
});

/*  21.09.12 追加｜商品詳細ページ > 見出しの移動
---------------------------------------------------*/
$(function(){
	if (!$('.c_himitsu').length) return;

	$(window).on('load resize', function(){
		var WindowWidth = $(window).width();
		if(WindowWidth < 768){
			$(".c_himitsu .box .img").each(function(){
				$(this).before($(this).parent().find(".ttl"));
				});
		} else {
			$(".c_himitsu .box .txt .exp").each(function(){
				$(this).before($(this).parent().parent().find(".ttl"));
				});
		}
	});
});

/*  21.09.12 追加｜九州セレクション記事ページ > サイドナビゲーションの固定表示
---------------------------------------------------*/
$(function() {
	if(!$('.p-kyushu-detail__body').length) return;

	$(window).on('load resize scroll', function(){
		var fixHeaderHeight = 80;
		var documentTop = $(this).scrollTop();
		var main = $(".p-kyushu-detail__body main");
		var aside = $(".p-kyushu-detail__body aside > div");
		var mainTop = main.offset().top - fixHeaderHeight;
		var mainHeight = main.height();
		var asideHeight = aside.height();
		var sabun = mainHeight - asideHeight - fixHeaderHeight;
		var asideStop = mainTop + sabun;
		if(documentTop >= mainTop) {
			aside.addClass('fix');
			aside.css('top',fixHeaderHeight);
			if(asideStop <= documentTop) {
				aside.addClass('stop');
				aside.css('top',sabun);
			} else {
				aside.removeClass('stop');
			}
		} else {
			aside.removeClass('fix');
		}
	});
});

/*  21.09.20 追加｜モーダルの背景固定
---------------------------------------------------*/
var current_scroll_y;
$(function(){
// modalを開いた時
	$('[data-toggle="modal"]').on('click', function(event){
		headerLock = true;
		current_scroll_y = $(window).scrollTop();
		$('body').css({
			position: 'fixed',
			top: -1 * current_scroll_y,
			left: 0,
			right: 0,
		});
	});
// modalを閉じた時
	$('[data-dismiss="modal"]').on('click', function(event){
		$('body').attr({style: ''});
		$('html, body').prop({scrollTop: current_scroll_y});
		headerLock = false;
	});
	$('.modal-open-space').on('click', function(event){
		$('body').attr({style: ''});
		$('html, body').prop({scrollTop: current_scroll_y});
		headerLock = false;
	});
});

/*  21.09.20 追加｜文字数制限
---------------------------------------------------*/
$(function(){
	var count = 50;
	$('.top-topic__item-desc, .l-item-list__item-desc, .c-item-list__item-desc, .category-item-list__item-desc').each(function(){
		var thisText = $(this).text();
		var textLength = thisText.length;
		if(textLength > count){
			var showText = thisText.substring(0, count);
			var insertText = showText += ' …';
			$(this).html(insertText);
		};
	});
});

/*  21.09.28 追加｜特殊カテゴリ > カテゴリ説明文の移動
---------------------------------------------------*/
$(function(){
	$(window).on('load resize', function(){
		var WindowWidth = $(window).width();
		if(WindowWidth < 768){
			$(".mentaiko-item__header").each(function(){
				$(this).append($(this).find("p"));
			});
		} else {
			$(".mentaiko-item__header").each(function(){
				$(this).children('div').append($(this).find("p"));
			});
		}
	});
});

/*  21.10.10 追加｜九州セレクション タイトルの高さ揃え
---------------------------------------------------*/
$(function(){
	$('.top-kyushu__post-title').matchHeight();
	$('.p-kyushu-list__post-title').matchHeight();
});